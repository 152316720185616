import React, { } from 'react'

function NoPage(){
    return (
        <div>
        Error! Page does not exist!
        </div>
    )
}

export default NoPage